import classNames from "classnames"
import React, {useState} from "react"

import DatePicker from "react-datepicker"
import DjangoCSRFToken from "django-react-csrftoken"
import {sv} from "date-fns/locale"

function formatDate(date?: Date) {
    return date ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` : undefined
}

function handleInputChange(setter: (value: string) => void) {
    return function(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setter(e.currentTarget.value)
    }
}

interface InquiryFromProps {
    initial: {
        [key: string]: boolean | string | null
        name: string | null
        email: string | null
        phone: string | null
        location: string | null
        start: string | null
        end: string | null
        message: string | null
    }
    errors: {
        name?: string
        email?: string
        phone?: string
        location?: string
        start?: string
        end?: string
        message?: string
    }
    nonFieldErrors: string[]
    submitUrl: string
}

export default function InquiryForm({initial, errors, nonFieldErrors, submitUrl}: InquiryFromProps) {
    // Field values and setters
    const [name, setName] = useState(initial.name || "")
    const [email, setEmail] = useState(initial.email || "")
    const [phone, setPhone] = useState(initial.phone || "")
    const [location, setLocation] = useState(initial.location || "")
    const [start, setStart] = useState(initial.start === null ? undefined : new Date(initial.start))
    const [end, setEnd] = useState(initial.end === null ? undefined : new Date(initial.end))
    const [message, setMessage] = useState(initial.message || "")

    return (
        <form id="boka" className="form" method="post" action={submitUrl}>
            <DjangoCSRFToken />
            <section>
                <ul className="fields">
                    <li className={classNames("field", {"has-error": errors.name})}>
                        <label htmlFor="name">Namn *</label>
                        <input
                            type="text"
                            name="name"
                            value={name}
                            onChange={handleInputChange(setName)}
                            required={true}
                        />
                        {errors.name && <p className="error">{errors.name}</p>}
                    </li>
                    <li className={classNames("field", {"has-error": errors.email})}>
                        <label htmlFor="email">E-postadress *</label>
                        <input
                            type="email"
                            name="email"
                            value={email}
                            onChange={handleInputChange(setEmail)}
                            required={true}
                        />
                        {errors.email && <p className="error">{errors.email}</p>}
                    </li>
                    <li className={classNames("field", {"has-error": errors.phone})}>
                        <label htmlFor="phone">Telefonnummer *</label>
                        <input
                            type="text"
                            name="phone"
                            inputMode="numeric"
                            value={phone}
                            onChange={handleInputChange(setPhone)}
                            required={true}
                        />
                        {errors.phone && <p className="error">{errors.phone}</p>}
                    </li>
                </ul>
                <p className="help">Ange era kontaktuppgifter för återkoppling.</p>

                <ul className="fields">
                    <li className={classNames("field", {"has-error": errors.start})}>
                        <label htmlFor="start">Från *</label>
                        <input name="start" type="hidden" value={formatDate(start)} />
                        <DatePicker
                            selected={start}
                            onChange={d => setStart(d as Date)}
                            required={true}
                            locale={sv}
                            dateFormat="yyyy-MM-dd"
                            placeholderText="Välj ett datum..."
                            />
                        {errors.start && <p className="error">{errors.start}</p>}
                    </li>
                    <li className={classNames("field", {"has-error": errors.end})}>
                        <label htmlFor="end">till</label>
                        <input name="end" type="hidden" value={formatDate(end)} />
                        <DatePicker
                            selected={end}
                            onChange={d => setEnd(d as Date)}
                            locale={sv}
                            dateFormat="yyyy-MM-dd"
                            placeholderText="Välj ett datum..."
                            
                        />
                        {errors.end && <p className="error">{errors.end}</p>}
                    </li>
                    <li className={classNames("field", {"has-error": errors.location})}>
                        <label htmlFor="location">Plats *</label>
                        <input name="location" type="text" value={location} onChange={handleInputChange(setLocation)} />
                        {errors.location && <p className="error">{errors.location}</p>}
                    </li>
                </ul>
                <p className="help">
                    Ange när och var (stad, lokal, adress etc.) eventet äger rum.
                </p>
    
                <ul className="fields">
                    <li className={classNames("field", { "has-error": errors.message })}>
                        <label htmlFor="message">Meddelande *</label>
                        <textarea
                            name="message"
                            value={message}
                            onChange={handleInputChange(setMessage)}
                            required={true}
                            placeholder="Hej Linkans!"
                            rows={10}
                            cols={50}
                        />
                        {errors.message && <p className="error">{errors.message}</p>}
                    </li>
                </ul>
                <p className="help">Berätta vad ni är ute efter och vad ni förväntar er av oss.</p>

                {nonFieldErrors.length > 0 && (
                    <ul className="errors">
                        {nonFieldErrors.map((message, idx) => <li key={idx}>{message}</li>)}
                    </ul>
                )}

                <div className="actions">
                    <button type="submit" className="button dark">SKICKA</button>
                    <p className="help">
                        Skicka din förfrågan till oss så återkopplar vi till
                        dig med prisförslag och eventuella frågor inom kort.
                    </p>
                </div>
            </section>
        </form>
    )
}
