import classNames from "classnames"
import React, { useState } from "react"

interface MenuItem {
    url: string
    text: string
}

interface MenuProps {
    menuItems: MenuItem[]
    hamburgerImagePath: string
    facebookImagePath: string
}

export default function Menu({menuItems, hamburgerImagePath, facebookImagePath}: MenuProps) {
    const [collapsed, setCollapsed] = useState(true)

    function toggleCollapse(e: React.MouseEvent) {
        e.preventDefault()
        setCollapsed(!collapsed)
    }

    return (
        <div className="top">
            <div className="menu">
                <div className="bar">
                    <a className="mobile toggle" onClick={toggleCollapse}>
                        <img className="icon" src={hamburgerImagePath} />
                    </a>
                    <a
                        href="https://www.facebook.com/linkansevent/"
                        rel="noopener"
                        target="_blank"
                        title="Följ oss på Facebook"
                        className="facebook"
                    >
                        <img className="icon" src={facebookImagePath} />
                    </a>
                </div>
                <div className={classNames("items", {collapsed: collapsed})}>
                    {menuItems.map(i => <a className="item" href={i.url} key={i.url}>{i.text}</a>)}
                </div>
            </div>
        </div>
    )
}
