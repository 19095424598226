import ReactDOM from "react-dom"

function getData(element: Element) {
    const dataElement = element
    if (dataElement === null) {
        throw Error("Missing data element for selector ${selector}`")
    }
    return Object.freeze(JSON.parse(dataElement.textContent))
}

export function renderToPlaceholder({ selector, useData }: { selector: string, useData?: boolean }, render: (data?: any) => JSX.Element) {
    document.addEventListener("DOMContentLoaded", () => {
        const placeholderElements = document.querySelectorAll(`${selector}.placeholder`)
        if (placeholderElements.length === 0) {
            throw Error(`Missing placeholder elements for selector ${selector}`)
        }

        placeholderElements.forEach(placeholderElement => {
            const reactElement = useData === false ? render() : render(getData(placeholderElement.firstElementChild))
            ReactDOM.render(reactElement, placeholderElement)
        })
    })
}
