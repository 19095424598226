import React from "react"

import InquiryForm from "./public/inquiry/form"
import Menu from "./public/menu/menu"
import { renderToPlaceholder } from "./public/utils"

renderToPlaceholder({selector: "#top_menu"}, data => {
    return (
        <Menu
            menuItems={data.menu_items}    
            hamburgerImagePath={data.hamburger_image_path}
            facebookImagePath={data.facebook_image_path}
        />
    )
})

renderToPlaceholder({ selector: "#inquiry_form" }, data => {
    return (
        <InquiryForm
            initial={data.initial}
            errors={data.errors}
            nonFieldErrors={data.non_field_errors}
            submitUrl={data.submit_url}
        />
    )
})
